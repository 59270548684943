import * as Yup from "yup";

import { default as React, useEffect, useMemo, useState } from "react";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";

import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import _ from "lodash";
import { useDispatch } from "react-redux";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import Checkbox from 'src/components/inputs/Checkbox/Checkbox';
import form from "src/styles/themes/form";

export default function EditAddresses() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const [isRepeatAddress, setIsRepeatAddres] = useState(false);

  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    let formatedValues = _.cloneDeep(
      context?.initialData?.groupCompany?.groupCompany
    );

    if (formatedValues?.registeredAddress?.country?.code) {
      formatedValues.registeredAddress.country =
        formatedValues?.registeredAddress?.country?.code;
    } else {
      delete formatedValues.registeredAddress;
    }
    if (formatedValues?.mailingAddress?.useRegisteredAddress) {
      setIsRepeatAddres(true);
    }
    if (formatedValues?.mailingAddress?.country?.code) {
      formatedValues.mailingAddress.country =
        formatedValues?.mailingAddress?.country?.code;
    } else {
      delete formatedValues.mailingAddress;
    }

    if (formatedValues?.locationCompanyRecordsAddress?.country?.code) {
      formatedValues.locationCompanyRecordsAddress.country =
        formatedValues?.locationCompanyRecordsAddress?.country?.code;
    } else {
      delete formatedValues.locationCompanyRecordsAddress;
    }

    if (formatedValues?.locationAccountingRecordsAddress?.country?.code) {
      formatedValues.locationAccountingRecordsAddress.country =
        formatedValues?.locationAccountingRecordsAddress?.country?.code;
    } else {
      delete formatedValues.locationAccountingRecordsAddress;
    }
    return formatedValues;
  }, [context]);

  const managedByClara = useMemo(() => {
    return context?.initialData?.groupCompany?.groupCompany.managedByClara;
  }, [context]);

  const isSPV = useMemo(() => {
    return context?.initialData.groupCompany.groupCompany.companyType === "SPV";
  }, [context]);

  const isTSL = useMemo(() => {
    return context?.initialData.groupCompany.groupCompany.companyType === "TSL";
  }, [context]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    // TODO ASK THE URL FOR THE LINK
    if (context?.initialData?.groupCompany?.groupCompany.managedByClara)
      dispatch(
        addAssistantText(
          translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_1"),
          "alert"
        )
      );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch, context.initialData]);
  const handleCheckMailingAddressTheSameAsRegisteredAddress = (value) => {

    setIsRepeatAddres(value);
    if (value) {
      const newValues = formRef?.current?.getValues("registeredAddress");
      formRef?.current?.setValue("mailingAddress", { ...newValues, useRegisteredAddress: true });
    }
  };
  const handleChangeRegisteredAddress = (value) => {
    if (isRepeatAddress) {
      formRef?.current?.setValue("mailingAddress", { ...value, useRegisteredAddress: true });
    }
  }


  const formRef = React.useRef();
  const schema = Yup.object().shape({
    registeredAddress: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
    mailingAddress: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
      useRegisteredAddress: Yup.boolean(),
    }),

    locationAccountingRecordsAddress: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      ref={formRef}
    >
      <TemplateWizard
        props={{
          title: "Edit addresses",
        }}
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_CONFIRM")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        <AddressInput
          name="registeredAddress"
          label={{
            country: translate("MODULES.CLARA.ADDRESS_REGISTERED_LABEL"),
          }}
          disabled={isSPV && managedByClara}
          banner={managedByClara}
          zipCodeOptional
          stateOptional
          isRequired
          onChange={handleChangeRegisteredAddress}
        />
        <div>&nbsp;</div>
        {isTSL && managedByClara && (
          <div>
            <ControllerInput
              label="The mailing address is the same as the registered address."
              isMultiple={false}
              name="mailingAddress.useRegisteredAddress"
              render={Checkbox}
              onChange={handleCheckMailingAddressTheSameAsRegisteredAddress}
            />
          </div>
        )}

        <AddressInput
          name="mailingAddress"
          label={{ country: "Mailing address" }}
          banner={managedByClara}
          zipCodeOptional
          isRequired
          disabled={isRepeatAddress}
        />
        <div>&nbsp;</div>

        {isTSL && (
          <>
            <AddressInput
              name="locationCompanyRecordsAddress"
              label={{ country: "Alternative location of company records" }}
              banner={managedByClara}
              zipCodeOptional
              isRequired
              disabled={isTSL}
            />
            <div>&nbsp;</div>
          </>
        )}

        <AddressInput
          name="locationAccountingRecordsAddress"
          label={{ country: "Location of accounting records" }}
          zipCodeOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}
