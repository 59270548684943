import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import { InputLabelWithBanner } from "src/components/inputs/InputWithBanner";
import React, { useEffect, useMemo } from "react";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import TextInput from "src/components/inputs/Text/TextInput";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";
import _ from "lodash";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { useFormContext } from "react-hook-form";

const AddressInput = ({
  name,
  label = {},
  isRequired = false,
  handleChangeCountry = () => { },
  zipCodeOptional = false,
  banner = false,
  fiedsToWatchBanner = undefined,
  disabled = false,
  onChange,
  bannerTitle = 'Select "Save changes" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.',
}) => {
  const { translate } = useTranslate();
  const fiedsToWatch = useMemo(() => {
    if (banner) {
      if (!fiedsToWatchBanner) return [
        `${name}`,
        `${name}.country`,
        `${name}.street`,
        `${name}.city`,
        `${name}.state`,
        `${name}.zipCode`,
      ];
      return fiedsToWatchBanner.map((field) => `${name}.${field}`);
    }
    return []
  }, [banner, fiedsToWatchBanner, name]);
  const { watch } = useFormContext();
  const address = {
    country: watch(`${name}.country`),
    street: watch(`${name}.street`),
    city: watch(`${name}.city`),
    state: watch(`${name}.state`),
    zipCode: watch(`${name}.zipCode`),

  }
  
  useEffect(() => {
    
    if (onChange) onChange(address);
  }, [JSON.stringify(address), onChange]);
  return (
    <>
      <TemplateLabel label={_.get(label, "country")} isRequired={isRequired}>
        <ControllerInput
          render={CountrySelector}
          name={`${name}.country`}
          placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_COUNTRY")}
          showStrong={true}
          onChange={handleChangeCountry}
          clear
          disabled={disabled}
        />
      </TemplateLabel>
      <TemplateLabel label={_.get(label, "street")}>
        <ControllerInput
          render={TextareaInput}
          name={`${name}.street`}
          placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_ADDRESS")}
          disabled={disabled}
        />
      </TemplateLabel>
      <TemplateLabel label={_.get(label, "city")}>
        <ControllerInput
          render={TextInput}
          name={`${name}.city`}
          placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_CITY")}
          disabled={disabled}
        />
      </TemplateLabel>
      <TemplateLabel label={_.get(label, "state")}>
        <ControllerInput
          render={TextInput}
          name={`${name}.state`}
          placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_STATE")}
          disabled={disabled}
          optional={true}
        />
      </TemplateLabel>
      {banner ? (
        <InputLabelWithBanner
          bannerLogicType="touched"
          component={TextInput}
          controllerInputProps={{
            name: `${name}.zipCode`,
            placeholder: translate("MODULES.CLARA.ADDRESS_INPUT_ZIP"),
            optional: zipCodeOptional,
            disabled,
          }}
          templateLabelProps={{
            bannerTitle: bannerTitle,
            label: _.get(label, "zipCode"),
          }}
          fieldsToWatch={fiedsToWatch}
        />
      ) : (
        <TemplateLabel label={_.get(label, "zipCode")}>
          <ControllerInput
            render={TextInput}
            name={`${name}.zipCode`}
            placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_ZIP")}
            optional={zipCodeOptional}
            disabled={disabled}
          />
        </TemplateLabel>
      )}
    </>
  );
};

export default AddressInput;
