import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import ClaraList from "src/modules/generic/components/Lists/ClaraList";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./classes.module.scss";

function ReviewAndConfirmStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [isChange, setIsChange] = useState();

  const initialValues = useMemo(() => {
    return context?.ReviewAndConfirmStep;
  }, [context]);
  
  const signatories = context?.initialData?.signatories;  
  
  const stakeholderList = useMemo(() => {
    return _.map(signatories, (signatory) => {
      const signatoryAvatar = signatory?.avatar ?? signatory?.fullName;
      const useClaraAvatar = signatory?.fullName === "Clara Nominee";
      return {
        name: signatory?.fullName,
        avatar: signatoryAvatar,
        isFounder: signatory?.isFounder,
        useClaraAvatar,
      };
    });
  }, [context, signatories]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    //TODO: LINK
    dispatch(
      addAssistantText(
        "<strong>Additional fees</strong> might apply if you need to update some of the details with the ADGM Registrar. Click here to learn more about the process and fees.",
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    confirm: Yup.boolean().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Edit ADGM Authorised Signatories",
          skeleton: null,
          subTitle: null,
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>{isChange ? "Confirm" : "Next"}</SubmitButton>,
        }}
      >
        <Typography component="h5">
          Your current Authorised Signatories are listed below:
        </Typography>
        <ClaraList variant="normal" items={stakeholderList} />
        <TemplateLabel
          label="Please confirm this is correct"
          isRequired
          className={classes.labelOnTop}
        >
          <ControllerInput
            name="confirm"
            render={CheckboxButtonSimple}
            onChange={(value) => {
              setIsChange(value);
            }}
            classNameButton={classes.buttonCheckBox}
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewAndConfirmStep;
